.display-medium {
  @apply text-6xl leading-tight tracking-tight;
}

.button {
  @apply inline-flex items-center  uppercase text-sm font-bold rounded-full shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary justify-center cursor-pointer hover:opacity-80 px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed;
}

.button {
  &.large {
    @apply px-8 py-5;
  }
}

.button-secondary {
  @apply relative text-sm whitespace-nowrap inline-flex items-center justify-center border border-primary hover:bg-neutral-200 hover:text-primary text-primary font-bold uppercase rounded-full px-4 py-2;
}

.button-secondary {
  &.large {
    @apply px-8 py-5;
  }
  &.blue {
    background: linear-gradient(265.47deg, #6149fe 0%, #2302fd 100%);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: #ffffff;
    &:hover {
      background: #2102ee;
    }
  }
}

.button-transparent {
  @apply inline-flex items-center border border-transparent uppercase text-sm font-bold rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center cursor-pointer px-4 py-2;
}
.button-transparent {
  &.large {
    @apply px-8 py-5;
  }
}

.input {
  @apply appearance-none relative block w-full px-3 py-2 border border-neutral-200 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:ring-1 focus:border-primary focus:z-[9] shadow-sm text-base bg-white;
}

.label {
  @apply block text-sm font-bold text-gray-700 mb-2;
}

.navbar-link {
  @apply inline-flex items-center px-1 text-base font-medium text-white hover:text-indigo-50 uppercase text-sm font-bold;
}

.icon-primary {
  @apply w-5 h-5 text-primary-400 mr-2
}

.wrapper {
  @apply mx-auto max-w-7xl px-5
}

.profile-wrapper-form {
  @apply bg-white p-8 rounded-3xl
}